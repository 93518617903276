.container {
    min-width: 30%;
    min-height: 300px;
    padding: .2em;
    background-color: white;
    border-radius: .75em;
    border: white .75em solid ;
    /* float: right; */
}


.card {
    min-height: 90%;
    min-width: 70%;
    /* max-width: 30%; */
    /* background-color: cadetblue; */
    /* margin: 10%; */
    padding: 5%;
    border-radius: .5em;
    /* border: white .5em solid ; */
    float: right;
    transition: all .2s ease-in-out; 

}

.card :hover {
    transform: scale(1.1);
    
}

.slogan {
    /* transition: all .2s ease-in-out;  */
    text-align: center;
    font-family: Brush Script MT;
    font-size: 3em;
    transform: matrix(1, -0.20, 0, 1, 0, 0);
    -ms-transform: matrix(1, -0.20, 0, 1, 0, 0);
    -webkit-transform: matrix(1, -0.20, 0, 1, 0, 0);
    text-shadow: 1px 2px #e6e6d8, 2px 3px #c6a39a;
}

.slogan :hover { 
    /* transform: scale(5);  */
    font-size: 4em;
}

