:root {
  --blue: #6495ed;
  --white: #faf0e6;
  --offWhite: rgb(255,255,220);
  --lightBlue: rgb(44, 173, 233);
  --green: rgb(48, 128, 74);
  --yellow: rgb(191, 194, 27);
  --orange: rgb(201, 152, 19);
  --red: rgb(240, 75, 10);
}

.body {
  height: 100%;
  overflow-x: hidden;
  /* overflow-y: hidden; */
}

h1 {
  font-size: 4em;
}


.App {
  text-align: center;
  background-color: 	var(--offWhite);
  min-width: 100%;
  min-height: 100%;
  /* z-index: 100; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: 	var(--offWhite);
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  color: black;
}

.body{
  position: relative;
  z-index: 1000;
  left: 18em;
  width: 80%;
  margin-left: 1em;
}

.intro {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about {
  /* width: 80%;
  text-align: center;
  margin: auto; */
  text-align: left;
  margin-left: 1em;
  height: 50vh;
}

.portfolio {
  text-align: left;
  height: 70vh;
}

.ribbon {
  position: fixed;
  left: 3em;
  /* top: 3px; */
  height: 100%;
  z-index: 50;
}

.blue {
  display: inline-block;
  background-color: var(--lightBlue);
  width: 4em;
  height: 100%;
}

.green {
  display: inline-block;
  background-color: var(--green);
  width: 3em;
  height: 100%;
}
.yellow {
  display: inline-block;
  background-color: var(--yellow);
  width: 2em;
  height: 100%;
}
.orange {
  display: inline-block;
  background-color: var(--orange);
  width: 4em;
  height: 100%;
}
.red {
  display: inline-block;
  background-color: var(--red);
  width: 2em;
  height: 100%;
}

.contact {
  
  cursor: pointer;
}
